@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 1199px) {
  .hero_wrapper .hero_row {
    padding: 70px 0 60px 0;
  }

  .hero_row .hero_icon_main {
    margin-left: 20px;
  }

  .video_text_main img,
  .video_play_button img {
    width: 85%;
  }

  .btn_block a {
    padding: 9px 45px;
  }

  .our_vision_sec {
    padding: 90px 0 50px 0;
  }

  .rewarding_loyalty_text {
    width: 55%;
  }

  .rewarding_loyalty_image {
    width: 45%;
  }

  .rewarding_loyalty_Sec {
    padding: 90px 0;
  }

  .LatestMarket_card {
    padding: 15px;
    height: unset;
  }

  .LatestMarket_card h5 {
    font-size: 18px;
    margin: 15px 0 20px 0;
  }

  .LatestMarket_card p {
    font-size: 15px;
    margin-top: unset;
  }

  .LatestMarket,
  .our_clients_Sec {
    padding: 50px 0 90px 0;
  }

  .laptop_video {
    width: 697px;
    height: 100%;
  }

  .laptop_video .VideoBLock {
    height: 430px;
  }

  .laptop_video {
    top: 56.2%;
  }

  .visit_paragraph p {
    font-size: 17px;
    margin-top: 40px;
  }

  .visit_marmar {
    padding: 90px 0 50px 0;
  }

  .clients_details {
    border-radius: 15px;
    padding: 20px;
  }

  .sem_pading {
    padding: 90px 0;
  }

  .ourauditors_logos ul li:nth-child(3) {
    text-align: end;
  }

  .ourauditors_logos ul li:nth-child(2) {
    text-align: center;
  }

  .ourauditors_logos ul li:nth-child(1) {
    text-align: start;
  }
}

@media (max-width: 991px) {
  .hero_wrapper .hero_row {
    flex-wrap: wrap;
    padding: 50px 0 20px 0;
  }

  .hero_row .hero_video {
    width: 100%;
  }

  .hero_row .hero_icon_main {
    width: 100%;
    max-width: 72%;
    margin: 40px auto 0 auto;
  }

  .companies_list ul {
    flex-wrap: wrap;
    justify-content: center;
  }

  .companies_list ul li {
    width: 33.33%;
    margin: 15px 0;
  }

  .companies_list ul li a {
    text-align: start;
  }

  .companies_list {
    padding: 20px 0;
  }

  .our_arrow_block {
    min-width: 240px;
  }

  .arrows .rotate {
    width: 70%;
  }

  .web_hadding {
    font-size: 40px;
  }

  .our_vision_text p {
    font-size: 17px;
    line-height: 1.5;
    margin-bottom: 25px;
  }

  .our_vision_text span {
    font-size: 19px;
  }

  .our_vision_sec {
    padding: 70px 0 40px 0;
  }

  .rewarding_row {
    flex-wrap: wrap;
  }

  .rewarding_row {
    flex-wrap: wrap;
  }

  .rewarding_loyalty_text,
  .rewarding_loyalty_image {
    width: 50%;
  }

  .rewarding_loyalty_image div:last-child {
    text-align: end;
  }

  .rewarding_loyalty_image div:last-child img {
    max-width: 90%;
  }

  .rewarding_loyalty_text p {
    font-size: 16px;
  }

  .web_hadding {
    font-size: 35px;
  }

  .btn_block a {
    padding: 8px 40px;
    font-size: 19px;
  }

  .footer {
    padding: 10px 0;
  }

  .copyright_block p {
    font-size: 16px;
  }

  .rewarding_loyalty_Sec {
    padding: 70px 0;
  }

  .card_center_row {
    justify-content: center;
  }

  .LatestMarket,
  .our_clients_Sec {
    padding: 40px 0 70px 0;
  }

  .laptop_video {
    width: 517px;
  }

  .laptop_video .VideoBLock {
    height: 320px;
  }

  .laptop_video .rh5v-Overlay_inner {
    width: 65px;
    height: 65px;
  }

  .visit_marmar {
    padding: 70px 0 40px 0;
  }

  .visit_paragraph p br {
    display: none;
  }

  .visit_paragraph p {
    font-size: 15px;
    margin-top: 30px;
  }

  .sem_pading {
    padding: 70px 0;
  }

  .OurPartners_sec .web_hadding {
    margin-bottom: 10px;
  }

  .ourauditors_logos ul li {
    margin-top: 25px;
  }

  .row {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1200px) {

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}

@media (max-width: 767px) {
  .swiper-slide {
    width: 300px;
    height: 412px;
  }

  .clients_details {
    width: 300px;
    height: 341px;
  }

  .logo_block a img {
    width: 90%;
  }

  .btn_block a {
    padding: 7px 38px;
    font-size: 18px;
    border-radius: 12px;
    font-weight: 500;
  }

  .video_text_main img,
  .video_play_button img {
    width: 80%;
  }

  .hero_wrapper .hero_row {
    padding: 40px 0 20px 0;
  }

  .arrows .rotate {
    width: 85%;
  }

  .our_arrow_block {
    min-width: 150px;
  }

  .web_hadding {
    font-size: 33px;
  }

  .our_vision_text span {
    font-size: 18px;
  }

  .our_vision_text p {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .our_vision_sec {
    padding: 60px 0 30px 0;
  }

  .rewarding_loyalty_text,
  .rewarding_loyalty_image {
    width: 100%;
  }

  .rewarding_loyalty_image {
    margin-top: 10px;
    flex-wrap: wrap;
  }

  .rewarding_loyalty_image .rewarding_loyalty_icon {
    width: 100%;
  }

  .rewarding_loyalty_image .rewarding_cup_img {
    width: 100%;
  }

  .rewarding_loyalty_image ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rewarding_loyalty_image ul li:not(:last-child) {
    margin-bottom: 0px;
    margin-right: 20px;
  }

  .rewarding_loyalty_image ul::before {
    left: 15%;
    top: 50%;
    width: 120px;
    height: 1px;
  }

  .rewarding_loyalty_image ul::after {
    right: -9%;
    left: unset;
    bottom: 50%;
    width: 120px;
    height: 1px;
  }

  .rewarding_loyalty_image div:last-child {
    text-align: center;
  }

  .rewarding_loyalty_Sec {
    padding: 60px 0;
  }

  .LatestMarket,
  .our_clients_Sec {
    padding: 30px 0 60px 0;
  }

  .LatestMarket_card>img {
    width: 100%;
  }

  .LatestMarket_card {
    margin-top: 30px;
  }

  .laptop_video {
    width: 382px;
  }

  .laptop_video .VideoBLock {
    height: 234px;
  }

  .laptop_video .rh5v-Overlay_inner {
    width: 50px;
    height: 50px;
  }

  .hero_video .rh5v-Overlay_inner {
    width: 65px;
    height: 65px;
  }

  .laptop_block_main .laptop_video .rh5v-Overlay_icon {
    margin-left: -17px;
    width: 70%;
  }

  .visit_marmar {
    padding: 60px 0 30px 0;
  }

  .clients_details>p {
    font-size: 14px;
  }

  .clients_details {
    padding: 15px;
  }

  .user_block .user_text p {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .hero_video .rh5v-Overlay_inner::before {
    left: -89%;
    top: -87%;
    background-size: 90%;
  }

  .sem_pading {
    padding: 40px 0;
  }

  .OurPartners_logos ul li {
    margin: 18px 0;
    width: 33.33%;
  }
}

@media (max-width: 575px) {
  .hero_heading_h1 {
    font-size: 36px;
  }

  .clients_details {
    min-height: 190px;
  }

  .btn_block a {
    padding: 7px 36px;
    font-size: 17px;
  }

  .our_vision_row {
    flex-wrap: wrap;
    justify-content: center;
  }

  .our_arrow_block {
    min-width: unset;
    text-align: center;
    margin-bottom: 10px;
  }

  .our_vision_sec {
    padding: 40px 0 30px 0;
  }

  .rewarding_loyalty_image div:last-child img {
    max-width: 55%;
  }

  .copyright_block p {
    font-size: 15px;
  }

  .rewarding_loyalty_Sec {
    padding: 40px 0;
  }

  .rewarding_loyalty_image ul::before {
    left: 13%;
    width: 25%;
  }

  .rewarding_loyalty_image ul::after {
    width: 25%;
    right: -12%;
  }

  .LatestMarket,
  .our_clients_Sec {
    padding: 30px 0 40px 0;
  }

  .visit_marmar {
    padding: 40px 0 30px 0;
  }

  .visit_paragraph p {
    font-size: 14px;
    margin-top: 20px;
  }

  .laptop_block>img {
    width: 450px;
  }

  .laptop_video {
    width: 336px;
  }

  .laptop_video .VideoBLock {
    height: 207px;
  }
}

@media (max-width: 450px) {

  .video_text_main img,
  .video_play_button img {
    width: 70%;
  }

  .hero_wrapper .hero_row {
    padding: 30px 0 20px 0;
  }

  .hero_row .hero_icon_main {
    margin-top: 30px;
  }

  .companies_list ul li {
    width: 50%;
  }

  .companies_list ul li:last-child a {
    text-align: start;
  }

  .btn_block a {
    padding: 7px 33px;
  }

  .arrows .rotate {
    width: 70%;
  }

  .rewarding_loyalty_text p {
    margin-bottom: 10px;
  }

  .rewarding_loyalty_image {
    margin-top: 0;
  }

  .copyright_block p {
    font-size: 14px;
  }

  .rewarding_loyalty_image div:last-child img {
    max-width: 50%;
  }

  .rewarding_loyalty_image ul::before {
    left: 11%;
    width: 22%;
  }

  .rewarding_loyalty_image ul::after {
    width: 22%;
    right: -11%;
  }

  .laptop_block>img {
    width: 370px;
  }

  .laptop_video {
    width: 276px;
  }

  .laptop_video .VideoBLock {
    height: 169px;
  }

  .hero_video .rh5v-Overlay_inner {
    width: 55px;
    height: 55px;
  }

  .hero_video .rh5v-Overlay_inner::before {
    left: -94%;
    top: -93%;
    background-size: 80%;
  }

  .ourauditors_logos ul li {
    width: 50%;
    margin-top: 20px;
  }

  .ourauditors_logos ul {
    flex-wrap: wrap;
    justify-content: center;
  }

  .ourauditors_logos ul li:nth-child(3) {
    text-align: center;
  }

  .OurPartners_logos ul li {
    width: 50%;
  }
}

@media (max-width: 400px) {
  .btn_block a {
    padding: 7px 30px;
    font-size: 15px;
  }

  .logo_block a img {
    width: 100%;
  }

  .hero_wrapper .hero_row {
    padding: 20px 0 0px 0;
  }

  .web_hadding {
    font-size: 30px;
  }

  .LatestMarket_card h5 {
    font-size: 16px;
  }

  /* .avatar_block .avatar_img_block span {
    font-size: 17px;
  } */

  .laptop_block>img {
    width: 337px;
  }

  .laptop_video {
    width: 250px;
  }

  .laptop_video .VideoBLock {
    height: 154px;
  }

  .laptop_video .rh5v-Overlay_inner {
    width: 40px;
    height: 40px;
  }

  .laptop_block_main .laptop_video .rh5v-Overlay_icon {
    margin-left: -14px;
    width: 70%;
  }
}

@media (max-width: 367px) {
  .btn_block a {
    padding: 6px 21px;
    font-size: 14px;
  }

  .hero_row .hero_icon_main>h5 {
    font-size: 17px;
  }

  .hero_icon>.icon_and_text span {
    font-size: 14px;
  }

  .hero_row .hero_icon_main {
    margin-top: 20px;
  }

  .arrows .rotate {
    width: 55%;
  }

  .web_hadding {
    font-size: 28px;
  }

  .rewarding_loyalty_image ul {
    padding: 25px 0;
  }

  .rewarding_loyalty_text p {
    font-size: 15px;
  }

  .rewarding_loyalty_Sec .web_hadding {
    margin-bottom: 15px;
  }

  .rewarding_loyalty_image ul::before {
    left: 8%;
    width: 15%;
  }

  .rewarding_loyalty_image ul::after {
    width: 15%;
    right: -7%;
  }

  .LatestMarket_card {
    padding: 10px;
  }

  .LatestMarket_card h5 {
    margin: 12px 0;
  }

  .laptop_block>img {
    width: 290px;
  }

  .laptop_video {
    width: 216px;
  }

  .laptop_video .VideoBLock {
    height: 132px;
  }

  .visit_paragraph p {
    font-size: 13px;
    margin-top: 15px;
  }

  .hero_video .rh5v-Overlay_inner::before {
    left: -85%;
    top: -84%;
    background-size: 75%;
  }
}

@media screen and (max-width: 850px) and (max-height: 375px) {
  .rewarding_loyalty_image div:last-child img {
    max-width: 175px;
  }
}