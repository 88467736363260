/*********************  Default-CSS  *********************/
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0
}

body {
  margin: 0;
  color: #000;
  background-color: #FAF1E0;
  font-size: 16px;
  line-height: 1;
  font-family: 'Poppins', sans-serif;
}

a,
span,
a:hover,
a:active,
button {
  text-decoration: none;
}

a,
div a:hover,
div a:active,
div a:focus,
button {
  text-decoration: none;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

p {
  margin: 0;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

.cm-overflow {
  overflow: hidden;
  touch-action: unset;
}

.row {
  margin: 0 -15px;
}

.container {
  padding: 0 15px;
}

.col,
[class*=col-] {
  padding: 0 15px;
}

::selection {
  background-color: rgb(15 118 91);
  color: #fff;
}

/*********************  Default-CSS  End *********************/

/*============== Header Css Start ===============*/
.header_wrapper {
  padding: 20px 0 0 0;
}

.logo_block a {
  display: inline-block;
  max-width: 277px;
}

.header_wrapper .row {
  align-items: center;
}

.header_wrapper .header_btn {
  text-align: right;
}

.btn_block a {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.5;
  background-color: rgb(15 118 91);
  color: #fff;
  border-radius: 18px;
  padding: 9px 53px;
  display: inline-block;
  border: 1px solid transparent;
}

.btn_block a:hover {
  background-color: transparent;
  border: 1px solid #0F765C;
  color: rgb(15 118 91);
}

.launch_button {
  max-width: 240px;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.5;
  background-color: rgb(15 118 91);
  color: #fff;
  border-radius: 12px;
  padding: 9px 24px;
  display: inline-block;
  border: 1px solid transparent;
}

.launch_button:hover {
  background-color: transparent;
  border: 1px solid #0F765C;
  color: rgb(15 118 91);
}

.hero_heading {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-basis: 100%;
}

.hero_heading_h1 {
  font-family: 'Frederic', Arial, sans-serif;
  font-weight: 900;
  font-size: 48px;
  text-transform: uppercase;
}

.hero_heading_h3 {
  font-family: 'Frederic', Arial, sans-serif;
  font-weight: 400;
  font-size: 22px;
}

.hero_heading_highlight {
  color: #249a7d;
}

/*============== Header Css End ===============*/

/*============== Banner Css Start ===============*/

.banner_wrapper {
  /* height: 48px; */
  width: 100%;
  z-index: 100;
}

/* @media (max-width: 757px){
    .banner_wrapper {
        height: 63.5px;
    }
}

@media (max-width: 432px){
    .banner_wrapper {
        height: 80px;
    }
} */

.banner_TopSiteBanner {
  padding: 16px 32px;
  z-index: 100;
  background: #0f765c;
  position: fixed;
  /* height: 50px; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  transition: all 0.25s ease-in-out;
  cursor: default;


}

.banner_TopSiteBanner:hover {
  background-color: #248d73;
}

.banner_content {
  max-width: 1170px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.banner_content .banner_countdown {
  flex-grow: 1;
  text-align: center;
}

.banner_countdown a {
  color: white;
  font-weight: 600;
  text-decoration: underline;
}

.banner_countdown a:hover {
  text-decoration: underline;
}

.banner_close img {
  cursor: pointer;
  max-width: unset;
}

.banner_launched {
  background: linear-gradient(-45deg, #0f765c, #0f765c, #0f765c, #0f765c, #248d73, #0f765c, #0f765c, #248d73, #0f765c, #0f765c, #0f765c, #0f765c), #248d73;
  background-size: 400% 400%, 0 0;
  -webkit-animation: Gradient 3.5s ease-in infinite;
  -moz-animation: Gradient 3.5s ease-in infinite;
  animation: Gradient 3.5s ease-in infinite;
}

.banner_launched:hover {
  background-position: initial, 0% 100%;
  background: #248d73;
  -webkit-animation-play-state: paused;
  /* Safari 4.0 - 8.0 */
  animation-play-state: paused;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}


/*============== Banner Css End ===============*/

/*============== hero Css Start ===============*/
.hero_wrapper .hero_row {
  width: 100%;
  display: flex;
  padding: 90px 0 80px 0;
  gap: 32px;
}

.hero_row .hero_video {
  flex-basis: 100%;
  position: relative;
  /* min-height: 404px; */
  overflow: hidden;
}

.hero_video .hero_iframe {
  width: 100%;
  min-height: 300px;
  aspect-ratio: 16 / 9;
  border-radius: 18px;
}

.video_text_main {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.hero_row .hero_video .VideoBLock {
  width: 100%;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  height: 100%;
}

.hero_row .hero_video .rh5v-DefaultPlayer_video {
  border-radius: 18px;
  object-fit: cover;
}

.hero_row .hero_video .rh5v-DefaultPlayer_controls {
  border-radius: 0 0 18px 18px;
}

.hero_video .rh5v-Overlay_inner,
.laptop_video .rh5v-Overlay_inner {
  background-color: #F3BA2A;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-left: unset;
  z-index: 1;
}

.hero_video .rh5v-Overlay_inner svg path {
  fill: rgb(155 166 124);
}

.hero_video .rh5v-Overlay_inner::before {
  content: "";
  position: absolute;
  left: -83%;
  top: -84%;
  width: 200px;
  height: 200px;
  background-image: url(./assets//images/Homepage/video_text.svg);
  background-repeat: no-repeat;
  z-index: -1;
}

.hero_video .rh5v-Volume_icon,
.laptop_video .rh5v-Volume_icon {
  padding: 2px;
}

.hero_row .hero_icon_main {
  width: 42%;
  margin-left: 30px;
  min-width: 320px;
}

.hero_row .hero_icon_main>h5 {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.4;
  color: #0F765C;
  margin-bottom: 10px;
}

.hero_icon_main .hero_icon>.icon_and_text a {
  display: inline-block;
}

.hero_icon_main .hero_icon>.icon_and_text {
  margin: 10px 0;
}

.hero_icon_main .hero_icon>.icon_and_text:nth-child(even) {
  text-align: end;
}

.hero_icon>.icon_and_text span {
  margin-top: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: #121212;
  display: block;
  text-align: center;
}

.animals_divider {
  background: linear-gradient(360deg, #faf1e0 0%, rgba(255, 255, 255, 0) 20%), url(./assets/images/Homepage/animal_silhouettes.png);
  background-size: contain;
  background-repeat: repeat-x;
  background-position: bottom center;
  aspect-ratio: 8/1;
  /* height: 320px; */
}

.companies_list {
  padding: 40px 0;
  z-index: 1;
  position: relative;
  width: 100%;
  background-color: #faf1e0;
}

.companies_list ul li:first-child a {
  text-align: start;
}

.companies_list ul li:last-child a {
  text-align: end;
}

.companies_list ul li a {
  display: inline-block;
  text-align: center;
  max-width: 166px;
}

.companies_list ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -15px;
}

.companies_list ul li {
  padding: 0 15px;
}

.companies_list ul li img {
  width: 100%;
}

ul li img.invert {
  filter: invert(.85);
}

.bg_block {
  background-image: url(./assets/images/Homepage/Background.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
}

.bg_block::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 120px;
  background: linear-gradient(360deg, #faf1e0 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 0;
}

/*============== hero Css End ===============*/

/*============== our_vision_sec Css Start ===============*/
.our_vision_sec {
  padding: 120px 0 60px 0;
}

.our_vision_row {
  display: flex;
}

.our_arrow_block {
  min-width: 300px;
}

.arrows .rotate {
  animation: rotation 8s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.our_vision_text span {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5;
  display: block;
  color: #0F765C;
  text-transform: capitalize;
}

.our_vision_sec .web_hadding {
  margin: 8px 0 20px 0;
}

.web_hadding {
  font-weight: 600;
  font-size: 44px;
  line-height: 1.23;
  color: #1D1D1B;
}

.our_vision_text p {
  font-weight: 300;
  font-size: 18px;
  line-height: 1.67;
  color: #3C3C3C;
}

.our_vision_text p {
  margin-bottom: 35px;
}

/*============== our_vision_sec Css End ===============*/

/*============== LatestMarket Css Start ===============*/
.LatestMarket {
  padding: 60px 0 120px 0;
}

.LatestMarket .web_hadding {
  text-align: center;
}

.LatestMarket_card {
  padding: 20px 20px 30px 20px;
  background: #FFFFFF;
  box-shadow: 0px 4px 30px rgba(166, 136, 79, 0.2);
  border-radius: 18px;
  /* margin-top: 40px; */
  height: 100%;
  display: flex;
  flex-direction: column;
}


.LatestMarket_card>a {
  display: block;
  overflow: hidden;
  border-radius: 18px;
  position: relative;
  z-index: 11;
}

.LatestMarket_card>a img {
  transition: all 0.4s ease;
  width: 100%;
}

.LatestMarket_card:hover>a img {
  transform: scale(1.08);
}

.LatestMarket_card h5 {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
  color: #000000;
  margin: 20px 0 30px 0;
  font-family: 'Ubuntu', sans-serif;
  transition: all 0.4s ease;
  min-height: 56px;
}

.LatestMarket_card:hover .LatestMarket_card h5 {
  color: #0F765C;
}

/* .avatar_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
} */

/* .avatar_block .avatar_img_block {
  display: flex;
  align-items: center;
}

.avatar_block .avatar_img_block span {
  margin-left: 10px;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  color: #000;
  font-family: 'Ubuntu', sans-serif;
} */

.LatestMarket_card p {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.12;
  color: #929292;
  font-family: 'Ubuntu', sans-serif;
  margin-top: auto;
}

/*============== LatestMarket Css End ===============*/

/*============== VisitMarmarSec Css Start ===============*/
.visit_marmar {
  padding: 120px 0 60px 0;
  background-image: url(./assets/images/Homepage/VisitMarmar_bg.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: 100% 713px;
  background-position: top;
  text-align: center;
}

.visit_paragraph p {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: #000000;
  text-align: center;
  margin-top: 60px;
}

.laptop_block {
  position: relative;
  display: inline-block;
}

.laptop_video {
  position: absolute;
  left: 50.3%;
  top: 44.2%;
  transform: translate(-50%, -50%);
  width: 730px;
  height: 460px;
}

.laptop_video .VideoBLock {
  height: 460px;
  width: 100%;
}

.laptop_video .rh5v-DefaultPlayer_video {
  width: 100%;
  object-fit: cover;
}

.laptop_play_icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.laptop_video .rh5v-Overlay_inner svg path {
  fill: rgb(25 25 25);
}

/*============== VisitMarmarSec Css End ===============*/

/*============== Our Clients Css Start ==============*/
.our_clients_Sec {
  padding: 60px 0 120px 0;
}

.our_clients_Sec .web_hadding {
  text-align: center;
  margin-bottom: 10px;
}

.our_clients_Sec .card_center_row {
  /* overflow-x: scroll; */
  /* flex-wrap: nowrap; */
  /* touch-action: pan-y; */
  /* padding-bottom: 30px; */
}

.LatestMarket .card_center_row>div {
  margin-top: 40px;
}

.swiper-container {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: center;
  width: 700px;
  height: 400px;
}

.clients_details {
  background: #FFFFFF;
  box-shadow: 0px 4px 30px rgba(166, 136, 79, 0.2);
  border-radius: 18px;
  padding: 30px;
  margin: auto;
  margin-top: 30px;
  min-height: 270px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 700px;
  height: 300px;
}

.clients_details>p {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.75;
  color: #121212;
  margin-bottom: 20px;
}

.user_block {
  display: flex;
  align-items: center;
}

.user_block img {
  border-radius: 100px;
}

.user_block .user_text {
  margin-left: 14px;
}

.user_block .user_text p {
  font-weight: 400;
  font-size: 18px;
  line-height: 1;
  color: #0F765C;
  margin-bottom: 10px;
}

.user_block .user_text span {
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: #121212;
}

/*============== Our Clients Css End ===============*/

/*============== rewarding_loyalty_Sec Css Start ==============*/
.rewarding_loyalty_Sec {
  padding: 120px 0;
  background-color: #F0D7A8;
  background-image: url(./assets/images/Homepage/rewarding_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.rewarding_row {
  display: flex;
  align-items: center;
  margin: 0 -15px;
}

.rewarding_row>div {
  padding: 0 15px;
}

.rewarding_loyalty_text {
  width: 59%;
}

.rewarding_loyalty_Sec .web_hadding {
  margin-bottom: 20px;
}

.rewarding_loyalty_text p {
  margin-bottom: 20px;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.67;
  color: #3C3C3C;
}

.rewarding_loyalty_text p:nth-child(2) {
  max-width: 600px;
}

.rewarding_loyalty_text p:nth-child(3) {
  max-width: 620px;
}

.rewarding_loyalty_Sec .btn_block {
  padding-top: 10px;
}

.rewarding_loyalty_image {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 41%;
}

.rewarding_loyalty_image ul {
  position: relative;
  padding: 35px 0 35px 0;
}

.rewarding_loyalty_image ul li:not(:last-child) {
  margin-bottom: 20px;
}

.rewarding_loyalty_image ul li a {
  background-color: #0F765C;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease;
}

.rewarding_loyalty_image ul li a svg path {
  transition: all 0.4s ease;
}

.rewarding_loyalty_image ul li a:hover {
  background-color: #fff;
}

.rewarding_loyalty_image ul li a:hover svg path {
  fill: #0F765C;
}

.rewarding_loyalty_image ul::before {
  content: '';
  position: absolute;
  left: 50%;
  top: -100px;
  transform: translateX(-50%);
  width: 1px;
  height: 100px;
  background-color: #0F765C;
}

.rewarding_loyalty_image ul::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -100px;
  transform: translateX(-50%);
  width: 1px;
  height: 100px;
  background-color: #0F765C;
}

/*============== rewarding_loyalty_Sec Css End ===============*/

/*============== OurAuditors Css Start ===============*/
.sem_pading {
  padding: 120px 0;
}

.ourauditors_sec {
  background-color: rgb(240 215 168 / 70%);
}

.ourauditors_sec .web_hadding {
  text-align: center;
}

.ourauditors_logos ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -15px;
}

.ourauditors_logos ul li {
  margin-top: 40px;
  padding: 0 30px;
}

.ourauditors_logos ul li a {
  opacity: 0.5;
  display: inline-block;
  max-width: 280px;
}

.ourauditors_logos ul li a:hover {
  opacity: 1;
}

/*============== OurAuditors Css End ===============*/

/*============== OurPartners Css Start ===============*/
.OurPartners_sec .web_hadding {
  text-align: center;
  margin-bottom: 40px;
}

.OurPartners_logos {
  overflow: hidden;
}

.OurPartners_logos ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 -25px;
}

.OurPartners_logos ul li {
  width: 25%;
  margin: 27px 0;
  padding: 0 25px;
  text-align: center;
}

.OurPartners_logos ul li a {
  opacity: 0.5;
  display: inline-block;
  width: 100%;
  max-width: 170px;
  max-height: 70px;
}

.OurPartners_logos ul li a img {
  width: 100%;
  max-width: 170px;
  max-height: 70px;
}

.OurPartners_logos ul li a:hover {
  opacity: 1;
}

/*============== OurPartners Css End ===============*/

/*============== footer Css Start ==============*/
.footer {
  padding: 12px 0;
}

.copyright_block p {
  font-weight: 300;
  font-size: 18px;
  line-height: 1.5;
  color: #1D1D1B;
  text-align: center;
}

.copyright_block p a {
  color: #1D1D1B;
}

.copyright_block p a:hover {
  color: #0F765C;
}

/*============== footer Css End ===============*/

/* 

  font-family: 'Ubuntu', sans-serif;

*/

.socials {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 32px;
  margin: 24px 32px;
  align-items: center;
}

.socials img {
  filter: invert(1);
}

.header_links {
  justify-content: end;
  display: flex;
  gap: 32px;
  padding: 0px;
}

.header_link {
  font-family: 'Frederic', Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #1D1D1B;
  text-align: center;
}

.header_link:hover {
  color: #0F765C;
}

/* .hero_wrapper {
  background: url(./assets/images/Homepage/animal_silhouettes.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
} */